<template>
  <user-sales-network
    v-if="salesNetworkUser"
    :sales-network-user="salesNetworkUser"
  />
</template>
<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import userStoreModule from '../../user/userStoreModule'
import agencyStoreModule from '../Agency/agencyStoreModule'
import UserSalesNetwork from './UserSalesNetwork.vue'
// import UserEditTabAccount from './UserEditTabAccount.vue'
// import UserEditTabInformation from './UserEditTabInformation.vue'
// import UserEditChangePassword from './UserEditChangePassword.vue'
// import companyStoreModule from '../../dealer/Company/companyStoreModule'

// Notification

export default {
  components: {
    // BTab,
    // BTabs,
    // BCard,
    // BAlert,
    // BLink,
    // UserEditTabAccount,
    // UserEditTabInformation,
    // UserEditChangePassword,
    UserSalesNetwork,
  },
  watch: {
    "$route.params": {
      handler(val, old) {
        try {
          this.initComponent(val.id)
          /*
          if (old == undefined) return
          if (val !== old) {
            // Object.assign(this.$data, initialData())
            this.initComponent()
          }
          */
        } catch (error) {
          console.log(error)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  setup() {
    const salesNetworkUser = ref(null)
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const AGENCY_DEALER_STORE_MODULE_NAME = 'app-agency'
    const COMPANY_DEALER_STORE_MODULE_NAME = 'app-company'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.registerModule(AGENCY_DEALER_STORE_MODULE_NAME, agencyStoreModule)
    // if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, companyStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(AGENCY_DEALER_STORE_MODULE_NAME)
      // if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })

    onMounted(() => {
    })

    const initComponent = idUser => {
      if (typeof idUser != "undefined") {
        store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
          .then(response => {
            salesNetworkUser.value = response.data
          }).catch(error => {
            if (error.response.status === 404) {
              salesNetworkUser.value = undefined
            }
          })
      } else {
        salesNetworkUser.value = getUserData()
      }
    }
    // initComponent()
    return {
      initComponent,
      salesNetworkUser,
    }
  },
  methods: {
  },
}
</script>

<style>

</style>
